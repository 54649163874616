<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-form @submit.prevent="saveForm" method="post" id="litter-form">
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Litter</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            label="Name"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="litter-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
      },
      errors: {},
    };
  },

  methods: {
    openForm: function () {
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/litters/saveLitter", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
    },
  },
};
</script>
